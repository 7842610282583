import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Img from "gatsby-image"

import "./sociallinks.scss"

export default () => {
  return (
		<StaticQuery
			query={graphql`
				query {
					linkedIn: file(relativePath: { eq: "logos/LinkedIn.png" }) {
						childImageSharp {
							fixed(width: 55, height: 45) {
								...GatsbyImageSharpFixed
							}
						}
					}

					gitHub: file(relativePath: { eq: "logos/GitHub.png" }) {
						childImageSharp {
							fixed(width: 50, height: 50) {
								...GatsbyImageSharpFixed
							}
						}
					}

					codePen: file(relativePath: { eq: "logos/Codepen.png" }) {
						childImageSharp {
							fixed(width: 50, height: 50) {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
			`}
			render={data => (
				<section className="social-pages-section">
					<div className="social-pages-section__page social-pages-section__page--github portfolio-item">
							<a href="https://github.com/gmichael89" target="_blank" title="GitHub Page">
									<div className="portfolio-item__logo">
											<Img fixed={data.gitHub.childImageSharp.fixed} className="company-logo" alt="GitHub" />
									</div>
									<div className="portfolio-item__label">
											<span>Go to my GitHub</span>
									</div>
							</a>
					</div>
					<div className="social-pages-section__page social-pages-section__page--codepen portfolio-item">
							<a href="https://codepen.io/gmichael89/" target="_blank" title="Codepen Page">
									<div className="portfolio-item__logo">
											<Img fixed={data.codePen.childImageSharp.fixed} className="company-logo" alt="Codepen" />
									</div>
									<div className="portfolio-item__label">
											<span>Go to my Codepen</span>
									</div>
							</a>
					</div>
				</section>
			)
		} />
	)
}
