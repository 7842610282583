import React from "react"

import Layout from "../components/layout/layout"
import SocialLinks from "../components/sociallinks/sociallinks"

export default class About extends React.Component {

  render() {
    return (
      <Layout title="About Me">
        <section>
          <h1>About Me</h1>
          <p>
            I'm a software developer based in Manchester, England. I specialise in the following frontend
            web technologies:
          </p>
          <ul>
            <li>JavaScript</li>
            <li>TypeScript</li>
            <li>CSS</li>
          </ul>
          <p>I also write C# when dealing with the backend-side of applications. As well as this, I am also Sitecore (8.1) qualified.</p>
        </section>
        <SocialLinks />
      </Layout>
    )
  }
}
